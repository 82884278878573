import React from "react"
import { convertToRaw } from "draft-js"
import { main_login } from "../../styles/login.module.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Layout from "../Layout"
import BlogForm from "./BlogForm"
import API from "../../apis"
import { navigate } from "gatsby"

const AddBlog = () => {
  const handleSubmit = async (values, editorState) => {
    try {
      const data = await API.addBlog({
        ...values,
        description: JSON.stringify(
          convertToRaw(editorState.getCurrentContent())
        ),
      })
      if (data?.error) {
        console.log(data.error)
        return
      }
      navigate("/blog")
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Layout>
      <div className="flex justify-center">
        <div
          className={`${main_login} md:m-20 xs:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1`}
          style={{ maxWidth: "845px" }}
        >
          <BlogForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </Layout>
  )
}

export default AddBlog
