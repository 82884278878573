import React from "react"
import { Router } from "@reach/router"
import { PrivateRoute } from "../../routes/PrivateRoute"
import AddBlog from "../../components/Blogs/AddBlog"
// import SingleBlog from "../../components/SingleBlog"

const App = () => {
  return (
      <Router basepath="/app">
        <PrivateRoute path="/addblog" component={AddBlog} />
        {/* <SingleBlog path="/blog/:id" /> */}
      </Router>
  )
}

export default App
