import { navigate } from "gatsby"
import React, { useContext } from "react"
import AuthContext from "./context"
import modalContext from "./ModalContext"

export const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { authState } = useContext(AuthContext)
  const { setOpen } = useContext(modalContext)
  if (!authState.isAuthenticated) {
    navigate("/blog")
    setOpen("login")
    return null
  }

  return <Component {...rest} />
}
